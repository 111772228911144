import { configureStore, combineReducers, AnyAction } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage/session';
// import hardSet from 'redux-persist/lib/stateReconciler/hardSet';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import { persistReducer, persistStore } from 'redux-persist';
import DisciplinesReducer from './stateSlices/disciplineSlice';
import ErrorReducer from './stateSlices/errorSlice';
import UserReducer from './stateSlices/userSlice';
import OfferReducer from './stateSlices/offerSlice';
import SortReducer from './stateSlices/sortSlice';
import SuggestionsReducer from './stateSlices/suggestionsSlice';
import ProductPairReducer from './stateSlices/productPairSlice';
import UserSessionReducer from './stateSlices/userSessionSlice';
import filterReducer from './stateSlices/filterSlice';

const persistConfig = {
  key: 'root',
  storage,
  stateReconciler: autoMergeLevel2,
};

export const appReducer = combineReducers({
  disciplines: DisciplinesReducer,
  error: ErrorReducer,
  user: UserReducer,
  offer: OfferReducer,
  sort: SortReducer,
  suggestions: SuggestionsReducer,
  productPair: ProductPairReducer,
  userSession: UserSessionReducer,
  filter: filterReducer,
});

const rootReducer = (state: any, action: AnyAction) => {
  if (action.type === 'RESET_STATE') {
    // for all keys defined in your persistConfig(s)
    storage.removeItem('persist:root');

    return appReducer(state, action);
  }
  const urlParams = new URLSearchParams(window.location.search);
  const context = urlParams.get('context');
  if (context) {
    storage.removeItem('persist:root');
  }
  return appReducer(state, action);
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export const persistor = persistStore(store);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof rootReducer>;
// Inferred type
export type AppDispatch = typeof store.dispatch;
