export const disciplineComponent = {
  height: '56px',
  background: 'rgba(0, 0, 0, 0.05)',
  '& .MuiSelect-select': {
    paddingLeft: '24px',
    paddingRight: '24px',
    paddingBottom: '8px',
  },
};

export const menuItem = {
  fontFamily: 'Open Sans',
  fontStyle: 'normal',
  fontWeight: '400',
  fontSize: '16px',
  lineHeight: '24px',
  fontColor: '#383838',
  flex: 'none',
  order: '0',
  alignSelf: 'stretch',
  flexGrow: '0',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'flex-start',
  padding: '6px 24px',
  '&.Mui-focusVisible': {
    '&:after': { border: 0 },
  },
};

export const inputLabelBeforeOnChange = {
  lineHeight: '24px',
  fontSize: '16px',
  fontFamily: 'Open Sans',
  fontWeight: '400',
  color: '#727272',
  paddingLeft: '24px',
  paddingRight: '24px',
  paddingBottom: '16px',
};

export const inputLabelAfterOnChange = (menuVisible: boolean) => ({
  lineHeight: '16px',
  fontSize: '12px',
  fontFamily: 'Open Sans',
  fontWeight: '400',
  color: menuVisible ? '#1B5BA0' : '#383838',
  fontStyle: 'normal',
  paddingLeft: '24px',
  paddingTop: '8px',
  transform: 'none',
  '&.Mui-focused': {
    color: menuVisible ? '#1B5BA0' : '#383838',
  },
});

export const disciplineContainer = {
  display: 'flex',
  flexDirection: 'row',
  marginBottom: '4%',
};

export const disciplineBox = {
  width: '100%',
};

export const paperProps = {
  maxHeight: '60%',
  lineHeight: '24px',
  fontSize: '16px',
  fontFamily: 'Open Sans',
  fontWeight: '400',
  color: '#383838',
  fontStyle: 'normal',
  '& .MuiMenuItem-root': {
    height: '36px',
  },
};

export const select = (menuVisible: boolean) => ({
  height: '56px',
  '& .MuiSelect-icon': {
    color: '#383838',
    position: 'absolute',
  },
  '& .MuiSvgIcon-root': {
    right: '25px',
  },
  background: 'rgba(0, 0, 0, 0.05)',
  lineHeight: '24px',
  fontSize: '16px',
  fontFamily: 'Open Sans',
  fontWeight: '400',
  color: '#383838',
  fontStyle: 'normal',
  '&:after': {
    border: menuVisible ? '1px solid #1B5BA0' : 'none',
  },
});
