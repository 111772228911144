import { createAsyncThunk } from '@reduxjs/toolkit';
import { getCookie } from '../../util/cookie';
import env from '../../common/env';

export const getUserSession = createAsyncThunk(
  'userSession/getUserSession',
  async (ltiLaunchId: string, thunkAPI) => {
    const token = getCookie('X-NG-JWT-TOKEN');
    const userSessionResponse = await fetchUserSession(token, ltiLaunchId);
    const responseData = await userSessionResponse.json();
    if (userSessionResponse.status === 200) {
      return responseData;
    } else {
      return thunkAPI.rejectWithValue({
        error: false,
        errorStatus: userSessionResponse.status,
        errorTitle: '',
        errorMessage: '',
        errorButtonName: '',
      });
    }
  }
);

const fetchUserSession = async (token: string, ltiLaunchId?: string) => {
  let url = env.USER_SESSION_URL;
  if (ltiLaunchId !== '') {
    url = url + '?ltiLaunchId=' + ltiLaunchId;
  }

  const response = await fetch(url, {
    method: 'GET',
    mode: 'cors',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response;
};
