// MUI
import { Button, Grid, Typography } from '@mui/material';

//React
import { useCallback, useEffect } from 'react';
import { ThreeDots } from 'react-loader-spinner';

// Images
import PairingSuccessImage from '../images/PairingSuccessImage.svg';

// Styles
import {
  continueText,
  gotItButton,
  successContainer,
  successImage,
  successText,
} from './pairingSuccessStyle';

// constance
import {
  GOT_IT,
  LTI_DEEP_LINKING_REQUEST,
  LTI_RESOURCE_LINK_REQUEST,
  PAIRING_SUCCESS,
  PAIRING_SUCCESS_CONTINUE,
} from '../constants';

// Redux
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { contentSelectionRedirect } from '../../../app/thunks/contentSelectionThunk';
import { buildPendoData } from '../../Search/container/functionDefinition/SearchContainerFunction';
import { getUserSession } from '../../../app/thunks/userSessionThunk';
import env from '../../../common/env';

export const ProductPairingSuccess = () => {
  const dispatch = useAppDispatch();

  const W3PIMessageType = useAppSelector((state) => state.user.W3PIMessageType);
  const ltiLaunchContextKey = useAppSelector(
    (state) => state.user.W3PILtiLaunchId
  );

  const userSession = useAppSelector((state) => state.userSession.userSession);
  const isUserSessionCompleted = useAppSelector(
    (state) => state.userSession.userSessionCompleted
  );

  const pendoData = useCallback(() => {
    return buildPendoData(userSession);
  }, [userSession]);

  const handleCTA = () => {
    dispatch({ type: 'RESET_STATE' });
    window.close();
  };

  useEffect(() => {
    if (isUserSessionCompleted) {
      if (env.PENDO_ENABLE && pendoData().visitor.productId) {
        try {
          (window as any).pendo && (window as any).pendo.identify(pendoData());
        } catch (err) {
          console.log('pendo identify error: ', err);
        }
      }
      if (W3PIMessageType === LTI_DEEP_LINKING_REQUEST) {
        contentSelectionRedirect(ltiLaunchContextKey);
      }
    }
  }, [pendoData]);

  useEffect(() => {
    if (ltiLaunchContextKey) {
      fetchUserSession();
    }
  }, [ltiLaunchContextKey]);

  const fetchUserSession = useCallback(() => {
    if (env.PENDO_ENABLE) {
      dispatch(getUserSession(ltiLaunchContextKey));
    }
  }, [ltiLaunchContextKey]);

  return (
    <Grid
      container
      sx={successContainer}
      direction="column"
      alignItems="center"
      justifyContent="center"
      rowSpacing={'2.5%'}
    >
      <Grid xs={12} item>
        <Typography sx={successText}>{PAIRING_SUCCESS}</Typography>
      </Grid>
      <Grid xs={12} item>
        <img
          src={PairingSuccessImage}
          alt="Pairing success image"
          aria-hidden="true"
          id="PairingSuccessImage"
          style={successImage}
        />
      </Grid>
      <Grid xs={12} item>
        <Typography sx={continueText}>
          {PAIRING_SUCCESS_CONTINUE(W3PIMessageType)}
        </Typography>
      </Grid>
      <Grid xs={12} md={12} lg={12} item>
        {W3PIMessageType === LTI_RESOURCE_LINK_REQUEST ? (
          <Button
            data-testid={'gotItButton'}
            sx={gotItButton}
            onClick={handleCTA}
          >
            {GOT_IT}
          </Button>
        ) : (
          <ThreeDots
            height="80"
            width="80"
            radius="9"
            color="#BAD1F7"
            ariaLabel="three-dots-loading"
            visible={true}
          />
        )}
      </Grid>
    </Grid>
  );
};
