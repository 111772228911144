const prod = {
  NODE_ENV: 'prod',
  DISCIPLINES_URL:
    'https://k9ky3aborg.execute-api.us-east-1.amazonaws.com/prod/api/v1/disciplines',
  USER_URL:
    'https://k9ky3aborg.execute-api.us-east-1.amazonaws.com/prod/api/v1/user',
  SEARCH_URL:
    'https://k9ky3aborg.execute-api.us-east-1.amazonaws.com/prod/api/v1/products/search',
  SUGGESTIONS_URL:
    'https://k9ky3aborg.execute-api.us-east-1.amazonaws.com/prod/api/v1/products/suggestions',
  LOGIN_URL: 'https://login.education.wiley.com/',
  MY_ACCOUNT_URL: 'https://myaccount.education.wiley.com/',
  COURSE_BUILDER_URL: 'https://education.wiley.com/course-builder/index.html',
  WILEY_READER_URL: 'https://read.wiley.com/books/',
  W3PI_PRODUCT_PAIRING_URL:
    'https://integrate.wiley.com/onboarding/api/v1/product/pairing',
  USER_SESSION_URL: 'https://login.education.wiley.com/v1/users/session',
  PENDO_ENABLE: true,
  W3PI_LTI_LAUNCH_URL: 'https://integrate.wiley.com/lti/launch/return',
};

export default prod;
