import { createSlice } from '@reduxjs/toolkit';
import { productPair } from '../thunks/productPairingThunk';

interface ProductPairState {
  loadingComplete: boolean;
  isProductPaired: boolean;
}

const initialState: ProductPairState = {
  loadingComplete: false,
  isProductPaired: false,
};

export const productPairSlice = createSlice({
  name: 'productPairing',
  initialState,
  reducers: {
    resetPairingState(state) {
      state.loadingComplete = false;
      state.isProductPaired = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(productPair.pending, (state: { loadingComplete: boolean }) => {
        state.loadingComplete = false;
      })
      .addCase(productPair.fulfilled, (state, action) => {
        state.isProductPaired = action.payload;
        state.loadingComplete = true;
      })
      .addCase(productPair.rejected, (state) => {
        state.loadingComplete = true;
      });
  },
});

export const { resetPairingState } = productPairSlice.actions;
export default productPairSlice.reducer;
