import { useEffect } from 'react';
// Mui Components
import { Button, AppBar, Toolbar, Typography } from '@mui/material';

// Style Definitions
import { appBar, title, toolbar } from './styleDefinitions/HeaderStyles';

// Constants
import { HEADER_TITLE, MY_ACCOUNT } from '../../../constants/common/header';

// Resources
import BackArrow from '../../../resources/images/BackArrow.png';

// Redux
import { useAppSelector, useAppDispatch } from '../../../app/hooks';

//env
import env from '../../../common/env';

import { useQuery } from '../../../util/route';

export const Header = () => {
  const query = useQuery();

  const dispatch = useAppDispatch();

  const launchContext = useAppSelector((state) => state.user.launchContext);
  const handleBack = () => {
    if (launchContext === MY_ACCOUNT) {
      window.location.href = env.MY_ACCOUNT_URL;
    }
  };

  useEffect(() => {
    const context = query.get('context');
    if (context) {
      dispatch({ type: 'RESET_STATE' });
    }
  }, []);

  useEffect(() => {
    const token = query.get('token');
    if (token) {
      document.cookie = `X-NG-JWT-TOKEN=${token}; path=/`;
    }
  }, []);

  useEffect(() => {
    const context = query.get('context');
    if (launchContext && context) {
      window.history.replaceState({}, document.title, '/');
    }
  }, [launchContext, query]);

  return (
    <AppBar color="primary" position="static" sx={appBar}>
      <Toolbar sx={toolbar}>
        {launchContext !== MY_ACCOUNT ? (
          <></>
        ) : (
          <Button
            size="small"
            variant="text"
            onClick={handleBack}
            data-testid={'back_button-test'}
          >
            <img alt="Back Button" src={BackArrow} />
          </Button>
        )}
        <Typography sx={title(launchContext)} variant="inherit">
          {HEADER_TITLE}
        </Typography>
      </Toolbar>
    </AppBar>
  );
};
