import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './styles.css';
import { W3PI } from '../../constants/common/header';
import { LTI_DEEP_LINKING_REQUEST } from '../ProductPairing/constants';

/**
 * Used to mock a PSD launch.
 * Navigate to the `/launch` path and fill in the fields.
 * 'W3PI' in the 'Launch Context' field requires more LTI params.
 */
export const Launch = () => {
  const [authToken, setAuthToken] = useState('');
  const [launchContext, setLaunchContext] = useState('');
  const [learningContextKey, setLearningContextKey] = useState('');
  const [messageType, setMessageType] = useState('');
  const [integrationKey, setIntegrationKey] = useState('');
  const [sourceContextKey, setSourceContextKey] = useState('');
  const [ltiLaunchId, setLtiLaunchId] = useState('');
  const [palmerSupported, setPalmerSupported] = useState('');

  const navigate = useNavigate();

  const handleSubmit = () => {
    document.cookie = `X-NG-JWT-TOKEN=${authToken}; path=/`;
    let navigateQuery = '';
    if (launchContext !== W3PI) {
      navigateQuery = `/?context=${launchContext}`;
      navigate(navigateQuery);
    } else {
      navigateQuery = `/?context=${launchContext}&ltiLaunchId=${ltiLaunchId}&learningContextKey=${learningContextKey}&messageType=${messageType}&integrationKey=${integrationKey}&sourceContextKey=${sourceContextKey}&isPalmerSupported=${palmerSupported}`;
      if (messageType === LTI_DEEP_LINKING_REQUEST) {
        navigateQuery += `&token=${authToken}`;
      }
      window.open(`${window.location.origin}${navigateQuery}`, '_blank');
    }
  };

  return (
    <>
      <form onSubmit={() => handleSubmit()}>
        <label>
          Auth Token:
          <input
            value={authToken}
            onChange={(e) => setAuthToken(e.target.value)}
          />
        </label>
        <label>
          Launch Context:
          <input
            value={launchContext}
            onChange={(e) => setLaunchContext(e.target.value)}
          />
        </label>
        {launchContext === W3PI && (
          <label>
            LTI Launch Id:
            <input
              value={ltiLaunchId}
              onChange={(e) => setLtiLaunchId(e.target.value)}
            />
          </label>
        )}
        {launchContext === W3PI && (
          <label>
            Integration Key:
            <input
              value={integrationKey}
              onChange={(e) => setIntegrationKey(e.target.value)}
            />
          </label>
        )}
        {launchContext === W3PI && (
          <label>
            Source Context Key:
            <input
              value={sourceContextKey}
              onChange={(e) => setSourceContextKey(e.target.value)}
            />
          </label>
        )}
        {launchContext === W3PI && (
          <label>
            Learning Context Key:
            <input
              value={learningContextKey}
              onChange={(e) => setLearningContextKey(e.target.value)}
            />
          </label>
        )}
        {launchContext === W3PI && (
          <label>
            Message Type:
            <input
              value={messageType}
              onChange={(e) => setMessageType(e.target.value)}
            />
          </label>
        )}
        {launchContext === W3PI && (
          <label>
            Palmer Supported (true/false):
            <input
              value={palmerSupported}
              onChange={(e) => setPalmerSupported(e.target.value)}
            />
          </label>
        )}
        <input className="submit_btn" type="submit" value="Submit" />
      </form>
    </>
  );
};
