import { Box, Grid, Skeleton } from '@mui/material';
import { sortContainer } from '../SortBy/styleDefinitions/SortByComponentStyles';
import {
  filterComponentGrid,
  searchResultLabelGrid,
  sortComponentGrid,
} from '../Search/container/styleDefinitions/SearchContainerStyles';
import { listViewContainer } from '../ListVew/styleDefinitions/ListViewStyles';
import { productCard } from './searchPreLoaderStyle';
import { useAppSelector } from '../../app/hooks';

export const SearchPreLoader = () => {
  const isPalmerSupported = useAppSelector(
    (state) => state.user.IsPalmerSupported
  );

  return (
    <>
      <Box sx={sortContainer}>
        <Grid sx={searchResultLabelGrid}>
          <Skeleton
            animation="pulse"
            height={27}
            variant="rectangular"
            width={'80%'}
          />
        </Grid>
        {isPalmerSupported && ( // disable FilterComponent unless palmer supported
          <Grid sx={filterComponentGrid}>
            <Box>
              <Skeleton animation="pulse" height={48} variant="rectangular" />
            </Box>
          </Grid>
        )}
        <Grid sx={sortComponentGrid}>
          <Box>
            <Skeleton animation="pulse" height={48} variant="rectangular" />
          </Box>
        </Grid>
      </Box>
      <Box sx={listViewContainer}>
        <Skeleton
          sx={productCard}
          animation="pulse"
          height={180}
          variant="rectangular"
        />
      </Box>
    </>
  );
};
