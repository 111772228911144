import React, { useEffect } from 'react';

import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { searchOffers } from '../../app/thunks/searchThunk';
import { setStateValue } from '../../app/stateSlices/disciplineSlice';
import { setSearchQuery } from '../../app/stateSlices/offerSlice';
import { resetSortOptions } from '../../app/stateSlices/sortSlice';

import { InputLabel, MenuItem, FormControl, Select } from '@mui/material';
import {
  disciplineComponent,
  inputLabelBeforeOnChange,
  inputLabelAfterOnChange,
  paperProps,
  menuItem,
  select,
} from './styleDefinitions/DisciplineComponentStyles';
import { DisciplineProps } from '../type/IDisciplineComponent';

import { initRequestBody } from '../../app/types/search';
import { READER } from '../../constants/common/search/platform';

export default function DisciplineComponent(props: DisciplineProps) {
  const [menuVisible, setMenuVisible] = React.useState(false);

  const loading = useAppSelector((state) => state.disciplines.loading);
  const inputLabelText = useAppSelector(
    (state) => state.disciplines.inputLabelText
  );
  const launchContext = useAppSelector((state) => state.user.launchContext);

  const dispatch = useAppDispatch();

  const isPalmerSupported = useAppSelector(
    (state) => state.user.IsPalmerSupported
  );

  const handleChange = (event: { target: { value: string } }) => {
    const selectedDiscipline = event.target.value;
    props.handleDiscipline(selectedDiscipline);
    if (selectedDiscipline !== undefined) {
      search(selectedDiscipline);
      dispatch(setStateValue({ field: 'visible', value: true }));
      dispatch(setStateValue({ field: 'inputLabelText', value: 'Discipline' }));
      // Below is a temporary solution to disable auth sorting for Demo
      selectedDiscipline !== 'Math & Statistics' &&
        dispatch(resetSortOptions());
    } else {
      dispatch(setStateValue({ field: 'visible', value: false }));
    }
  };

  const search = (discipline: string) => {
    if (isPalmerSupported) {
      const requestBody = initRequestBody({ discipline, launchContext });
      dispatch(searchOffers(requestBody));
    } else {
      // if palmer is not enabled, then force only reader products
      const filters = [
        {
          field: 'platform',
          values: [READER],
        },
      ];
      const requestBody = initRequestBody({
        discipline,
        launchContext,
        filters,
      });
      dispatch(searchOffers(requestBody));
    }
    dispatch(setSearchQuery(''));
  };

  const hideMenu = () => {
    setMenuVisible(false);
  };

  useEffect(() => {
    window.addEventListener('resize', hideMenu);
    return () => window.removeEventListener('resize', hideMenu);
  });

  return (
    <div>
      <FormControl variant="filled" fullWidth sx={disciplineComponent}>
        <InputLabel
          sx={
            inputLabelText === 'Discipline'
              ? inputLabelAfterOnChange(menuVisible)
              : inputLabelBeforeOnChange
          }
          id="demo-simple-select-autowidth-label"
        >
          {inputLabelText}
        </InputLabel>
        <Select
          labelId="demo-simple-select-filled-label"
          id="demo-simple-select-filled"
          data-testid={'discipline_component-test'}
          color="primary"
          open={menuVisible}
          disabled={loading}
          onOpen={() => {
            setMenuVisible(true);
          }}
          onClose={() => {
            setMenuVisible(false);
          }}
          MenuProps={{
            PaperProps: { sx: paperProps },
            disableScrollLock: true,
          }}
          sx={select(menuVisible)}
          value={props.discipline ?? ''}
          onChange={handleChange}
        >
          {props.options?.map((option) => {
            return (
              <MenuItem
                id="menu_item_test"
                sx={menuItem}
                key={option.discipline}
                value={option.discipline}
              >
                {`${option.discipline} (${option.count})`}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </div>
  );
}
