import { createAsyncThunk } from '@reduxjs/toolkit';
import { getCookie } from '../../util/cookie';
import env from '../../common/env';
import {
  AUTH_ERROR_BUTTON_NAME,
  AUTH_ERROR_MESSAGE,
  AUTH_ERROR_TITLE,
  AUTH_ERROR_STATUS,
} from '../../constants/common/error';

export const getUser = createAsyncThunk(
  'user/getUser',
  async (arg, thunkAPI) => {
    const token = getCookie('X-NG-JWT-TOKEN');
    try {
      const userResponse = await fetchUser(token);
      if (userResponse.status === 401) {
        return thunkAPI.rejectWithValue({
          error: true,
          errorStatus: AUTH_ERROR_STATUS,
          errorTitle: AUTH_ERROR_TITLE,
          errorMessage: AUTH_ERROR_MESSAGE,
          errorButtonName: AUTH_ERROR_BUTTON_NAME,
        });
      }
      const responseData = await userResponse.json();
      return responseData;
    } catch (error) {
      return {
        error: true,
        errorTitle: '',
        errorMessage: '',
      };
    }
  }
);

const fetchUser = async (token: string) => {
  const url = env.USER_URL;

  const response = await fetch(url, {
    method: 'GET',
    mode: 'cors',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response;
};
