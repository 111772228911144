import env from '../../common/env';

/**
 * sends a request to the specified url from a form. this will change the window location.
 * @param {string} path the path to send the post request to
 * @param {object} params the parameters to add to the url
 * @param {string} [method] the method to use on the form
 */

const formSubmission = (path: string, params: any, method: string) => {
  const form = document.createElement('form');
  form.method = method;
  form.action = path;

  for (const key in params) {
    if (params.hasOwnProperty(key)) {
      const hiddenField = document.createElement('input');
      hiddenField.type = 'hidden';
      hiddenField.name = key;
      hiddenField.value = params[key];

      form.appendChild(hiddenField);
    }
  }
  document.body.appendChild(form);
  form.submit();
};

export const contentSelectionRedirect = (ltiLaunchContextKey: string) => {
  const url = env.W3PI_LTI_LAUNCH_URL;
  formSubmission(
    url,
    { ltiLaunchId: ltiLaunchContextKey, postPairing: 'true' },
    'GET'
  );
};
