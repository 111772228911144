const qa = {
  NODE_ENV: 'qa',
  DISCIPLINES_URL:
    'https://87mnywwwt8.execute-api.us-east-1.amazonaws.com/qa/api/v1/disciplines',
  USER_URL:
    'https://87mnywwwt8.execute-api.us-east-1.amazonaws.com/qa/api/v1/user',
  SEARCH_URL:
    'https://87mnywwwt8.execute-api.us-east-1.amazonaws.com/qa/api/v1/products/search',
  SUGGESTIONS_URL:
    'https://87mnywwwt8.execute-api.us-east-1.amazonaws.com/qa/api/v1/products/suggestions',
  LOGIN_URL: 'https://login.education-qa.wiley.com/',
  MY_ACCOUNT_URL: 'https://myaccount.education-qa.wiley.com/',
  COURSE_BUILDER_URL:
    'https://education-qa.wiley.com/course-builder/index.html',
  WILEY_READER_URL: 'https://qa.read.wiley.com/books/',
  W3PI_PRODUCT_PAIRING_URL:
    'https://qa.integrate.wiley.com/onboarding/api/v1/product/pairing',
  USER_SESSION_URL: 'https://login.education-qa.wiley.com/v1/users/session',
  PENDO_ENABLE: true,
  W3PI_LTI_LAUNCH_URL: 'https://qa.integrate.wiley.com/lti/launch/return',
};

export default qa;
