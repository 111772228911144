import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { useEffect, useState } from 'react';
import { VIEW_PRODUCTS } from '../../constants/common/header';
import {
  filterComponent,
  inputLabel,
  menuItem,
  paperProps,
  select,
} from './styleDefinitions/FilterComponentStyle';

import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { setFilterBy } from '../../app/stateSlices/filterSlice';
import { setSearchFrom } from '../../app/stateSlices/offerSlice';
import { FilterProps } from '../type/IFilterComponent';
import { filterByList } from './filterByList';

export default function FilterComponent(props: FilterProps) {
  const [menuVisible, setMenuVisible] = useState(false);

  const dispatch = useAppDispatch();

  const handleChange = (event: { target: { value: string } }) => {
    dispatch(setSearchFrom(0));
    dispatch(setFilterBy(event.target.value));
    props.handleFilter(event.target.value);
  };

  const filterBy = useAppSelector((state) => state.filter.filterBy);

  const hideMenu = () => {
    setMenuVisible(false);
  };

  useEffect(() => {
    window.addEventListener('resize', hideMenu);
    return () => window.removeEventListener('resize', hideMenu);
  });

  return (
    <div>
      <FormControl variant="filled" fullWidth sx={filterComponent}>
        <InputLabel sx={inputLabel} id="filter-label">
          {VIEW_PRODUCTS}
        </InputLabel>
        <Select
          labelId="filter_component-filled-label"
          id="filter_component-filled"
          data-testid={'filter_component-test'}
          color="primary"
          open={menuVisible}
          onOpen={() => {
            setMenuVisible(true);
          }}
          onClose={() => {
            setMenuVisible(false);
          }}
          MenuProps={{
            PaperProps: { sx: paperProps },
            disableScrollLock: true,
          }}
          sx={select(menuVisible)}
          value={filterBy ?? filterByList[0]}
          onChange={handleChange}
        >
          {filterByList?.map((value, index) => {
            return (
              <MenuItem
                id="sortby_menu_item_test"
                sx={menuItem}
                key={index}
                value={value}
              >
                {value}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </div>
  );
}
