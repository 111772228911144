import { MY_ACCOUNT } from '../../constants/common/header';

interface Filters {
  field: string;
  values: Array<string>;
}

export interface SuggestionRequestBody {
  inputQuery?: string;
  launchContext: string;
  filters?: Array<Filters>;
  discipline: string;
}

export const initSuggestionReqBody = (
  options?: Partial<SuggestionRequestBody>
): SuggestionRequestBody => {
  const defaults = {
    launchContext: MY_ACCOUNT,
    discipline: '',
  };

  return {
    ...defaults,
    ...options,
  };
};
