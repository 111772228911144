import Joi from 'joi';

import {
  MY_ACCOUNT,
  W3PI,
  COURSE_DASHBOARD,
} from '../../../constants/common/header';
import {
  LTI_RESOURCE_LINK,
  LTI_DEEP_LINKING,
} from '../../../constants/common/launchParam';

export const launchTypeSchema = Joi.string()
  .valid(MY_ACCOUNT, W3PI, COURSE_DASHBOARD)
  .required();

export const w3piSchema = Joi.object({
  integrationKey: Joi.string().guid().required(),
  learningContextKey: Joi.string().guid().required(),
  ltiLaunchId: Joi.string().guid().required(),
  messageType: Joi.string()
    .valid(LTI_RESOURCE_LINK, LTI_DEEP_LINKING)
    .required(),
  sourceContextKey: Joi.string().required(),
  isPalmerSupported: Joi.boolean(),
});
