export const HEADER_TITLE = 'Wiley Learning';
export const DISCIPLINE_TITLE = 'Find your product in';
export const NO_RESULTS_FOUND_LABEL =
  'We didn’t find anything matching your search. Try again?';
export const SELECT_BUTTON = 'Select';
export const PREVIEW_BUTTON = 'Preview';
export const READ_MORE_BUTTON = 'READ MORE';
export const LOAD_MORE = 'LOAD MORE';
export const SORT_BY_LABEL = 'Sort by';
export const AUTHOR_LABEL = 'By';
export const MY_ACCOUNT = 'MY_ACCOUNT';
export const W3PI = 'W3PI';
export const COURSE_DASHBOARD = 'COURSE_DASHBOARD';
export const VIEW_PRODUCTS = 'View Products';
