export const AUTH_ERROR_STATUS = 401;
export const AUTH_ERROR_TITLE = 'You are not currently logged in';
export const AUTH_ERROR_MESSAGE = 'Please log in to your account to continue.';
export const AUTH_ERROR_BUTTON_NAME = 'LOG IN';
export const PARAMETER_ERROR_TITLE = 'Required parameter(s) missing';
export const PARAMETER_ERROR_MESSAGE =
  'There has been a system error. If the issue persists, please contact customer support.';
export const PARAMETER_ERROR_BUTTON_NAME = 'TRY AGAIN';
export const OK_BUTTON_NAME = 'OK, GOT IT';
export const REDIRECTION_ERROR_TITLE = 'Something went wrong';
