export const noResultsFoundContainer = {
  marginTop: '2.5%',
  justifyContent: 'center',
  display: 'flex',
};

export const noResultsFoundImg = {
  width: '300px',
  height: '227px',
};

export const noResultsFoundLabelContainer = {
  marginTop: '2.0%',
  justifyContent: 'center',
  display: 'flex',
  marginBottom: '5%',
};

export const noResultsFoundLabel = {
  fontFamily: 'Open Sans',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: '24px',
  fontSize: '16px',
  color: '#383838',
  display: 'flex',
  alignItems: 'center',
};
