import { useState, useEffect } from 'react';
import { InputLabel, MenuItem, FormControl, Select } from '@mui/material';
import { SortByList, SortByProps } from '../type/ISortByComponent';
import {
  inputLabel,
  menuItem,
  select,
  sortComponent,
  paperProps,
} from './styleDefinitions/SortByComponentStyles';
import { SORT_BY_LABEL } from '../../constants/common/header';
// Redux
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { setSortBy, setSortOptions } from '../../app/stateSlices/sortSlice';

export default function SortByComponent(props: SortByProps) {
  const [menuVisible, setMenuVisible] = useState(false);

  const discipline = useAppSelector(
    (state) => state.disciplines.selectedDiscipline
  );

  const { options } = props;

  const dispatch = useAppDispatch();

  const handleChange = (event: { target: { value: string } }) => {
    dispatch(setSortBy(event.target.value));
    const sortObject = options.find(
      (option) => option.value === event.target.value
    ) as SortByList;
    props.handleSort(sortObject);
  };

  const hideMenu = () => {
    setMenuVisible(false);
  };

  useEffect(() => {
    window.addEventListener('resize', hideMenu);
    return () => window.removeEventListener('resize', hideMenu);
  });

  // Below is a temporary solution to disable auth sorting for Demo
  useEffect(() => {
    if (discipline === 'Math & Statistics') {
      const updatedOptions = options.map((option) => {
        if (option.value === 'AUTHOR-ASC' || option.value === 'AUTHOR-DESC') {
          return {
            ...option,
            disabled: true,
          };
        }
        return option;
      });
      dispatch(setSortOptions(updatedOptions));
    }
  }, [discipline]);

  return (
    <div>
      <FormControl
        variant="filled"
        disabled={props.noResultFound}
        fullWidth
        sx={sortComponent}
      >
        <InputLabel sx={inputLabel} id="demo-simple-select-autowidth-label">
          {SORT_BY_LABEL}
        </InputLabel>
        <Select
          labelId="sort_by_component-filled-label"
          id="sort_by_component-filled"
          data-testid={'sort_by_component-test'}
          color="primary"
          open={menuVisible}
          onOpen={() => {
            setMenuVisible(true);
          }}
          onClose={() => {
            setMenuVisible(false);
          }}
          MenuProps={{
            PaperProps: { sx: paperProps },
            disableScrollLock: true,
          }}
          sx={select(menuVisible, props.noResultFound)}
          value={props.sortBy ?? 'RELEASED_DATE-DESC'}
          onChange={handleChange}
        >
          {options?.map(({ option, value, disabled }, index) => {
            return (
              <MenuItem
                id="sortby_menu_item_test"
                sx={menuItem}
                key={index}
                value={value}
                disabled={disabled}
              >
                {option}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </div>
  );
}
