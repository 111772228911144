import { createAsyncThunk } from '@reduxjs/toolkit';
import { RequestBody } from '../types/productPair';
import env from '../../common/env';
import { getCookie } from '../../util/cookie';

export const productPair = createAsyncThunk(
  'productPair',
  async (data: RequestBody, thunkAPI) => {
    const searchResponse = await execSearchRequest(data);
    if (searchResponse.status === 204) {
      return true;
    }
    return thunkAPI.rejectWithValue(false);
  }
);

const execSearchRequest = async (
  requestBody: RequestBody
): Promise<Response> => {
  const url = env.W3PI_PRODUCT_PAIRING_URL;
  const token = getCookie('X-NG-JWT-TOKEN');

  const response = await fetch(url, {
    method: 'POST',
    mode: 'cors',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(requestBody),
  });

  return response;
};
