import { useNavigate } from 'react-router-dom';

// MUI
import { Button, Grid, Typography } from '@mui/material';

//images
import PairingFailureImage from '../images/PairingFailureImage.svg';

// styles
import {
  failureImage,
  failureText,
  tryAgainButton,
  retryText,
  failureContainer,
} from './pairingFailureStyle';

//constant
import {
  PAIRING_FAILURE,
  PAIRING_FAILURE_RETRY,
  TRY_AGAIN,
} from '../constants';

// Redux
import { useAppDispatch } from '../../../app/hooks';
import { resetPairingState } from '../../../app/stateSlices/productPairSlice';

export const ProductPairingFailure = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handleCTA = () => {
    dispatch(resetPairingState());
    navigate('/');
  };

  return (
    <Grid
      container
      sx={failureContainer}
      direction="column"
      alignItems="center"
      justifyContent="center"
      rowSpacing={'2.5%'}
    >
      <Grid xs={12} item>
        <Typography sx={failureText}>{PAIRING_FAILURE}</Typography>
      </Grid>
      <Grid xs={12} item>
        <img
          src={PairingFailureImage}
          alt="Pairing failure image"
          aria-hidden="true"
          id="PairingFailureImage"
          style={failureImage}
        />
      </Grid>
      <Grid xs={12} item>
        <Typography sx={retryText}>{PAIRING_FAILURE_RETRY}</Typography>
      </Grid>
      <Grid xs={12} md={12} lg={12} item>
        <Button
          data-testid={'tryAgainButton'}
          sx={tryAgainButton}
          onClick={handleCTA}
        >
          {TRY_AGAIN}
        </Button>
      </Grid>
    </Grid>
  );
};
