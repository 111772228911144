export const searchResultsLabel = {
  fontFamily: 'Open Sans',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: '27px',
  fontSize: '20px',
  color: '#383838',
  display: 'flex',
  alignItems: 'center',
};
