import base from './base';
import dev from './dev';
import qa from './qa';
import uat from './uat';
import prod from './prod';
import local from './local';

let environment;
const { hostname } = window.location;
let envType;

if (hostname.includes('dev')) envType = 'dev';
else if (hostname.includes('qa')) envType = 'qa';
else if (hostname.includes('perf')) envType = 'uat';
else if (hostname.includes('localhost')) envType = 'localhost';
else if (hostname === 'discover.education.wiley.com') envType = 'prod';

switch (envType) {
  case 'dev':
    environment = { ...base, ...dev };
    break;
  case 'qa':
    environment = { ...base, ...qa };
    break;
  case 'uat':
    environment = { ...base, ...uat };
    break;
  case 'prod':
    environment = { ...base, ...prod };
    break;
  case 'localhost':
    environment = { ...base, ...local };
    break;
  default:
    environment = { ...base };
}

const env = environment;
export default env;
