export const successContainer = {
  justifyContent: 'center',
  margin: '16px',
  display: 'flex',
  width: 'auto',
};

export const successText = {
  fontFamily: 'Open Sans',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: '36px',
  fontSize: '24px',
  color: '#383838',
  textAlign: 'center',
};

export const successImage = {
  maxWidth: '100%',
  height: 'auto',
};

export const continueText = {
  fontFamily: 'Open Sans',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: '27px',
  fontSize: '20px',
  color: '#383838',
  textAlign: 'center',
};

export const gotItButton = {
  width: 'auto',
  height: '35px',
  borderRadius: '4px',
  backgroundColor: '#FFFFFF',
  color: '#383838',
  lineHeight: '20px',
  fontSize: '14px',
  fontFamily: 'Open Sans',
  fontWeight: '600',
  transform: 'none',
  minWidth: '74px',
  boxShadow: 'none',
  border: '1px solid #C4C4C4',
};
