const dev = {
  NODE_ENV: 'local',
  DISCIPLINES_URL: 'https://psd-service.dev.tc.wiley.host/api/v1/disciplines',
  USER_URL: 'https://psd-service.dev.tc.wiley.host/api/v1/user',
  SEARCH_URL: 'https://psd-service.dev.tc.wiley.host/api/v1/products/search',
  SUGGESTIONS_URL:
    'https://psd-service.dev.tc.wiley.host/api/v1/products/suggestions',
  LOGIN_URL: 'https://login.education-dev.wiley.com/',
  MY_ACCOUNT_URL: 'https://myaccount.education-dev.wiley.com/',
  COURSE_BUILDER_URL:
    'https://education-dev.wiley.com/course-builder/index.html',
  WILEY_READER_URL: 'https://dev.read.wiley.com/books/',
  W3PI_PRODUCT_PAIRING_URL:
    'https://dev.integrate.wiley.com/onboarding/api/v1/product/pairing',
  USER_SESSION_URL: 'https://login.education-dev.wiley.com/v1/users/session',
  PENDO_ENABLE: false,
  W3PI_LTI_LAUNCH_URL: 'https://dev.integrate.wiley.com/lti/launch/return',
};

export default dev;
