import { createSlice } from '@reduxjs/toolkit';
import { getSuggestions } from '../thunks/suggestionsThunk';

interface Suggestions {
  suggestions: Array<string>;
}

const initialState: Suggestions = {
  suggestions: [],
};

export const suggestionsSlice = createSlice({
  name: 'suggestions',
  initialState,
  reducers: {
    setSuggestions(state, action) {
      state.suggestions = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getSuggestions.fulfilled, (state, action) => {
      state.suggestions = action.payload;
    });
  },
});

export const { setSuggestions } = suggestionsSlice.actions;
export default suggestionsSlice.reducer;
