import { Box, Button } from '@mui/material';
import { LOAD_MORE } from '../../constants/common/header';
import { loadMoreButton, loadMoreComponent } from './loadMoreStyle';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { setSearchFrom } from '../../app/stateSlices/offerSlice';
import { LoadMoreProps } from '../type/ILoadMoreProps';

export const LoadMore = (props: LoadMoreProps) => {
  const searchFrom = useAppSelector((state) => state.offer.searchFrom);

  const dispatch = useAppDispatch();

  const handleClick = () => {
    dispatch(setSearchFrom(searchFrom + 10));
    props.setIsLoadSearchResults(true);
  };
  return (
    <Box data-testid={'load_more_test'} sx={loadMoreComponent}>
      <Button
        data-testid={'loadMoreButton'}
        sx={loadMoreButton}
        onClick={handleClick}
      >
        {LOAD_MORE}
      </Button>
    </Box>
  );
};
