export const container = {
  margin: '2.5% 15% 0 15%',
  justifyContent: 'center',
};

export const noDisciplineSelectedImgContainer = {
  marginTop: '7.5%',
  justifyContent: 'center',
};

export const noDisciplineSelectedImg = {
  padding: '40px, 80px, 0px, 80px',
  width: '100%',
  height: 'auto',
};

export const searchResultLabelGrid = {
  width: '60%',
  display: 'flex',
  flexDirection: 'row',

  '@media (max-width: 600px)': {
    width: '100%',
    justifyContent: 'center',
    marginBottom: '2%',
  },
};

export const sortComponentGrid = {
  width: '16em',

  '@media (max-width: 600px)': {
    marginTop: '2%',
  },
};

export const filterComponentGrid = {
  width: '16em',
  marginRight: '1%',

  '@media (max-width: 600px)': {
    marginTop: '1%',
    marginLeft: '1%',
  },
};
