import { Part } from '../../../type/ISearchComponent';

export const searchContainer = {
  marginTop: '2%',
  width: '100%',
  justifyContent: 'center',
};

export const searchBox = {
  width: '100%',
  marginBottom: '3%',
};

export const listBoxProps = {
  maxHeight: '170px',
  fontSize: '16px',
  fontFamily: 'Open Sans',
  fontStyle: 'normal',
  lineHeight: '24px',
};

export const textFieldInputProps = {
  fontSize: '16px',
  fontFamily: 'Open Sans',
  fontStyle: 'normal',
  lineHeight: '24px',
  fontWeight: 400,
  paddingLeft: '20px',
  paddingRight: '24px',
  paddingBottom: '8px',
  paddingTop: '20px',
};

export const listBoxItem = (part: Part) => ({
  fontWeight: part.highlight ? 700 : 400,
  fontFamily: 'Open Sans',
  fontStyle: 'normal',
  fontSize: '16px',
  lineHeight: '24px',
});

export const textField = (
  noResultFound: boolean,
  selectedInputValue: string
) => ({
  height: '56px',
  display: 'flex',
  flexDirection: 'row',
  background: 'rgba(0, 0, 0, 0.05)',
  lineHeight: '24px',
  boxShadow:
    selectedInputValue === ''
      ? 'inset 0px -1px 0px #727272'
      : noResultFound
      ? 'inset 0px -2px 0px #1B5BA0'
      : 'inset 0px -1px 0px #727272',
  '& .MuiInputLabel-root':
    selectedInputValue !== ''
      ? {
          fontSize: '12px',
          fontFamily: 'Open Sans',
          fontStyle: 'normal',
          lineHeight: '16px',
          fontWeight: 400,
          display: 'flex',
          alignItems: 'center',
          color: noResultFound ? '#1B5BA0' : '#383838',
          paddingLeft: '24px',
          paddingTop: '8px',
          transform: 'none',
        }
      : {
          fontSize: '16px',
          fontFamily: 'Open Sans',
          fontStyle: 'normal',
          lineHeight: '24px',
          fontWeight: 400,
          color: '#727272',
          paddingLeft: '24px',
          alignItems: 'center',
        },
  '& .MuiFormLabel-root.Mui-focused': {
    fontSize: '12px',
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    lineHeight: '16px',
    fontWeight: 400,
    display: 'flex',
    alignItems: 'center',
    color: '#1B5BA0',
    paddingLeft: '24px',
    paddingTop: '8px',
    transform: 'none',
  },
});

export const listItem = {
  lineHeight: '44px',
  paddingLeft: '24px',
  paddingRight: '24px',
};

export const suggestionItem = {
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap' as const,
};

export const clearIcon = {
  visibility: 'visible',
  color: '#727272',
  width: '20px',
  height: '20px',
  paddingLeft: '8.33%',
  paddingRight: '8.33%',
};

export const autoComplete = {
  '& .MuiInputBase-input': {
    padding: '0px',
  },
};
