import { Action, AnyAction, createSlice } from '@reduxjs/toolkit';
// import { getUser } from '../thunks/userThunk';

interface ErrorState {
  error: boolean;
  errorStatus: number;
  errorTitle: string;
  errorMessage: string;
  errorButtonName: string;
}

const initialState: ErrorState = {
  error: false,
  errorStatus: 400,
  errorTitle: '',
  errorMessage: '',
  errorButtonName: '',
};

interface RejectedAction extends Action {
  payload: ErrorState;
}

const isAuthFailureAction = (action: AnyAction): action is RejectedAction => {
  return action.type.endsWith('rejected');
};

export const errorSlice = createSlice({
  name: 'Error',
  initialState,
  reducers: {
    raiseError: (state, action) => {
      state.error = action.payload.error;
      state.errorTitle = action.payload.errorTitle;
      state.errorMessage = action.payload.errorMessage;
      state.errorButtonName = action.payload.errorButtonName;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(isAuthFailureAction, (state, action) => {
      const payload = action.payload as ErrorState;
      if (payload.errorStatus === 401) {
        state.error = payload.error;
        state.errorTitle = payload.errorTitle;
        state.errorMessage = payload.errorMessage;
        state.errorButtonName = payload.errorButtonName;
      }
    });
  },
});

export const { raiseError } = errorSlice.actions;
export default errorSlice.reducer;
