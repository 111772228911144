import { SortByList } from '../type/ISortByComponent';

export const sortByList: Array<SortByList> = [
  {
    option: 'Release Date (Newest)',
    value: 'RELEASED_DATE-DESC',
    sortField: 'RELEASED_DATE',
    order: 'DESC',
    disabled: false,
  },
  {
    option: 'Release Date (Oldest)',
    value: 'RELEASED_DATE-ASC',
    sortField: 'RELEASED_DATE',
    order: 'ASC',
    disabled: false,
  },
  {
    option: 'Title (A-Z)',
    value: 'TITLE-ASC',
    sortField: 'TITLE',
    order: 'ASC',
    disabled: false,
  },
  {
    option: 'Title (Z-A)',
    value: 'TITLE-DESC',
    sortField: 'TITLE',
    order: 'DESC',
    disabled: false,
  },
  {
    option: 'Author (A-Z)',
    value: 'AUTHOR-ASC',
    sortField: 'AUTHOR',
    order: 'ASC',
    disabled: false,
  },
  {
    option: 'Author (Z-A)',
    value: 'AUTHOR-DESC',
    sortField: 'AUTHOR',
    order: 'DESC',
    disabled: false,
  },
  {
    option: 'Relevance',
    value: 'RELEVANCE-ASC',
    sortField: 'RELEVANCE',
    order: 'DESC',
    disabled: true,
  },
];
