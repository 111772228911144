import { useState, useCallback, useEffect } from 'react';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import LaunchIcon from '@mui/icons-material/Launch';
import Divider from '@mui/material/Divider';
import { Box, Button, CardContent, Chip, Collapse } from '@mui/material';
import {
  authorLabel,
  authorLabelContainer,
  cardContainer,
  cardContent,
  cardHeading,
  cardHeadingContainer,
  chip,
  imageContainer,
  image,
  selectButton,
  selectButtonContainer,
  card,
  previewButton,
  readMoreButton,
  readMoreButtonContainer,
  cardFooter,
  cardBody,
  expandMoreContainer,
  description,
} from './styleDefinitions/CardComponentStyles';
import { CardProps } from '../type/ICardComponent';
import {
  AUTHOR_LABEL,
  MY_ACCOUNT,
  PREVIEW_BUTTON,
  READ_MORE_BUTTON,
  SELECT_BUTTON,
  W3PI,
} from '../../constants/common/header';
import GenericProductImage from '../../resources/images/productImageGeneric.svg';
import env from '../../common/env';
import {
  ALF_COURSE_WARE,
  E_BOOK,
} from '../../constants/common/search/platform';
import { setIsReadMoreOpen } from '../../app/stateSlices/offerSlice';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { productPair } from '../../app/thunks/productPairingThunk';
import { initProductPairReqBody } from '../../app/types/productPair';
import { setPlatformForPairing } from '../Search/container/functionDefinition/SearchContainerFunction';

export default function CardComponent(props: CardProps) {
  const [isReadMore, setIsReadMore] = useState(false);

  const dispatch = useAppDispatch();

  const isReadMoreOpen = useAppSelector((state) => state.offer.isReadMoreOpen);
  const expanded: boolean = isReadMoreOpen[props.productIndex] === 'open';
  const launchContext = useAppSelector((state) => state.user.launchContext);
  const W3PILearningContextKey = useAppSelector(
    (state) => state.user.W3PILearningContextKey
  );
  const W3PILtiLaunchId = useAppSelector((state) => state.user.W3PILtiLaunchId);
  const W3PIIntegrationKey = useAppSelector(
    (state) => state.user.W3PIIntegrationKey
  );
  const W3PISourceContextKey = useAppSelector(
    (state) => state.user.W3PISourceContextKey
  );

  const { isbn } = props;
  const { cover } = props;
  const { platform } = props;
  let { authors } = props;

  useEffect(() => {
    const descriptionText = props.description
      ? props.description.replace(/(<([^>]+)>)/gi, '')
      : '';
    const descriptionTextLength = descriptionText.length;
    setIsReadMore(descriptionTextLength > 0);
  }, [props.description]);

  const handleExpandClick = () => {
    dispatch(setIsReadMoreOpen(props.productIndex));
  };

  const handleClick = () => {
    if (launchContext === MY_ACCOUNT) {
      props.setNavigateToCB(true);
      props.setSelectedIsbn(isbn);
    } else if (launchContext === W3PI) {
      const requestBodyParameters = {
        sourceContextKey: W3PISourceContextKey,
        productId: isbn,
        ltiLaunchId: W3PILtiLaunchId,
        integrationKey: W3PIIntegrationKey,
        learningContextKey: W3PILearningContextKey,
        targetPlatform: setPlatformForPairing(platform),
      };
      const requestBody = initProductPairReqBody(requestBodyParameters);
      dispatch(productPair(requestBody));
    }
  };

  const handlePreviewClick = () => {
    window.open(`${env.WILEY_READER_URL}${props.bookId}`, '_blank');
  };

  const getCover = useCallback(() => {
    if (platform === 'Demo') {
      return GenericProductImage;
    } else if (platform === 'Courseware') {
      return props.eBooks[0].cover;
    }
    return cover;
  }, [platform]);

  const getAuthors = useCallback(() => {
    if (platform === 'Courseware') {
      authors = props.eBooks[0].authors;
    }
    const arrLength = authors ? authors.length : 0;

    let authorsList = '';
    for (let i = 0; i < arrLength; i++) {
      if (i === arrLength - 1) {
        authorsList += authors[i];
      } else if (i === arrLength - 2) {
        authorsList += authors[i] + ' and ';
      } else {
        authorsList += authors[i] + ', ';
      }
    }

    return authorsList;
  }, [authors]);

  return (
    <Card sx={card} data-testid={'card_component-test'}>
      <Box sx={cardBody}>
        <Box sx={cardContainer}>
          <Box sx={imageContainer}>
            <CardMedia
              data-testid={'productCardThumbnail'}
              sx={image}
              component="img"
              image={getCover()}
              alt={`${props.title}`}
            />
          </Box>
          <Box sx={cardContent}>
            <Box sx={cardHeadingContainer}>
              <Typography
                data-testid={'productCardTitle'}
                sx={cardHeading}
                variant="body1"
              >
                {props.title}
              </Typography>
            </Box>
            <Box sx={authorLabelContainer}>
              <Typography
                data-testid={'authorText'}
                variant="body1"
                sx={authorLabel}
              >
                {platform !== 'Demo' ? `${AUTHOR_LABEL} ${getAuthors()}` : ''}
              </Typography>
            </Box>
            <Box>
              <Chip data-testid={'platform'} label={props.platform} sx={chip} />
              {isbn && props.platform === ALF_COURSE_WARE && (
                <Chip data-testid={'eBookTag'} label={E_BOOK} sx={chip} />
              )}
            </Box>
            <Box sx={expandMoreContainer(expanded)}>
              <Collapse in={expanded} timeout="auto" unmountOnExit>
                <CardContent>
                  <Typography
                    id="description"
                    data-testid={'description'}
                    sx={description}
                    dangerouslySetInnerHTML={{
                      __html: expanded ? props.description : '',
                    }}
                  />
                </CardContent>
              </Collapse>
            </Box>
            <Box sx={readMoreButtonContainer}>
              {isReadMoreOpen[props.productIndex] === 'closed' &&
                isReadMore && (
                  <Button
                    data-testid={'readMoreButton'}
                    sx={readMoreButton}
                    onClick={handleExpandClick}
                  >
                    {READ_MORE_BUTTON}
                  </Button>
                )}
            </Box>
          </Box>
        </Box>
        <Divider />
        <Box sx={cardFooter}>
          <Box sx={selectButtonContainer}>
            {platform === E_BOOK ? (
              <Button
                data-testid={'previewButton'}
                sx={previewButton}
                onClick={handlePreviewClick}
                endIcon={<LaunchIcon />}
              >
                {PREVIEW_BUTTON}
              </Button>
            ) : (
              <></>
            )}
            <Button
              data-testid={'selectButton'}
              variant="contained"
              sx={selectButton}
              onClick={handleClick}
            >
              {SELECT_BUTTON}
            </Button>
          </Box>
        </Box>
      </Box>
    </Card>
  );
}
