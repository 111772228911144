import { createSlice } from '@reduxjs/toolkit';
import { UserSessionResponse } from '../../components/type/IUserSession';
import { getUserSession } from '../thunks/userSessionThunk';

interface UserSessionState {
  userSession: UserSessionResponse;
  userSessionCompleted: boolean;
}

const initialState: UserSessionState = {
  userSession: {
    userId: null,
    courseId: null,
    ltiContextId: null,
    institutionId: null,
    institutionName: null,
    role: null,
    productId: null,
    productTitle: null,
    lmsType: null,
    disciplines: [],
    signUpDate: null,
    failures: null,
  },
  userSessionCompleted: false,
};

export const userSessionSlice = createSlice({
  name: 'UserSession',
  initialState,
  reducers: {
    userSessionCompleted(state, action) {
      state.userSessionCompleted = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserSession.fulfilled, (state, action) => {
        state.userSession = action.payload;
        state.userSessionCompleted = true;
      })
      .addCase(getUserSession.rejected, (state) => {
        state.userSessionCompleted = true;
      });
  },
});

export const { userSessionCompleted } = userSessionSlice.actions;
export default userSessionSlice.reducer;
