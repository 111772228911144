export const PAIRING_SUCCESS = "You're all set!";
export const PAIRING_SUCCESS_CONTINUE = (messageType: string) => {
  if (messageType == LTI_DEEP_LINKING_REQUEST) {
    return 'You will be redirected to select your eBook links.';
  }
  return 'Wiley content has been added to your LMS course.';
};
export const GOT_IT = 'OK, GOT IT';
export const PAIRING_FAILURE = 'Something went wrong :(';
export const PAIRING_FAILURE_RETRY =
  'Pairing was unsuccessful. Please retry pairing on the previous screen.';
export const TRY_AGAIN = 'TRY AGAIN';
export const LTI_DEEP_LINKING_REQUEST = 'LtiDeepLinkingRequest';
export const LTI_RESOURCE_LINK_REQUEST = 'LtiResourceLinkRequest';
