import { MY_ACCOUNT } from '../../constants/common/header';

interface Filters {
  field: string;
  values: Array<string>;
}

export interface RequestBody {
  searchQuery?: string;
  launchContext: string;
  discipline: string;
  from: number;
  size?: number;
  sort: string;
  sortOrder: string;
  filters?: Array<Filters>;
}

export const initRequestBody = (
  options?: Partial<RequestBody>
): RequestBody => {
  const defaults = {
    launchContext: MY_ACCOUNT,
    discipline: '',
    from: 0,
    size: 10,
    sort: 'RELEASED_DATE',
    sortOrder: 'DESC',
  };

  return {
    ...defaults,
    ...options,
  };
};
