const uat = {
  NODE_ENV: 'uat',
  DISCIPLINES_URL:
    'https://53c55u8jg1.execute-api.us-east-1.amazonaws.com/uat/api/v1/disciplines',
  USER_URL:
    'https://53c55u8jg1.execute-api.us-east-1.amazonaws.com/uat/api/v1/user',
  SEARCH_URL:
    'https://53c55u8jg1.execute-api.us-east-1.amazonaws.com/uat/api/v1/products/search',
  SUGGESTIONS_URL:
    'https://53c55u8jg1.execute-api.us-east-1.amazonaws.com/uat/api/v1/products/suggestions',
  LOGIN_URL: 'https://login.education-perf.wiley.com/',
  MY_ACCOUNT_URL: 'https://myaccount.education-perf.wiley.com/',
  COURSE_BUILDER_URL:
    'https://education-perf.wiley.com/course-builder/index.html',
  WILEY_READER_URL: 'https://uat.read.wiley.com/books/',
  W3PI_PRODUCT_PAIRING_URL:
    'https://perf.integrate.wiley.com/onboarding/api/v1/product/pairing',
  W3PI_LTI_LAUNCH_URL: 'https://perf.integrate.wiley.com/lti/launch',
  USER_SESSION_URL: 'https://login.education-perf.wiley.com/v1/users/session',
  PENDO_ENABLE: true,
};

export default uat;
