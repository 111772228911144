import { createSlice } from '@reduxjs/toolkit';
import { getDisciplines } from '../thunks/disciplineThunk';
import { DisciplineState, SetStateAction } from '../types/disciplines';

const initialState: DisciplineState = {
  values: [],
  loading: true,
  selectedDiscipline: '',
  visible: false,
  inputLabelText: 'your discipline',
  menuVisible: false,
};

export const disciplineSlice = createSlice({
  name: 'disciplines',
  initialState,
  reducers: {
    setStateValue(state, action) {
      const payload = action.payload as SetStateAction;
      state[payload.field] = payload.value;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDisciplines.pending, (state: { loading: boolean }) => {
        state.loading = true;
      })
      .addCase(getDisciplines.fulfilled, (state, action) => {
        state.loading = false;
        state.values = action.payload;
      })
      .addCase(getDisciplines.rejected, (state) => {
        state.loading = false;
      });
  },
});

export const { setStateValue } = disciplineSlice.actions;
export default disciplineSlice.reducer;
