export const cardComponentContainer = {
  width: '100%',
  height: '148px',
  display: 'contents',
};

export const imageContainer = {
  width: '90px',
  // height: '116px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  marginBottom: '12px',
};

export const image = {
  width: '90px',
};

export const cardContainer = {
  display: 'flex',
  flexDirection: 'row',
  padding: '16px 16px 0 16px',
};

export const cardContent = {
  display: 'flex',
  flexDirection: 'column',
  marginLeft: '16px',
  width: '100%',
};

export const cardBody = {
  background: '#FFFFFF',
};

export const cardHeadingContainer = {
  marginTop: '8px',
};

export const cardHeading = {
  lineHeight: '24px',
  fontSize: '16px',
  fontFamily: 'Open Sans',
  fontWeight: '600',
  color: '#000000',
  fontStyle: 'normal',
  transform: 'none',
};

export const authorLabelContainer = {
  marginTop: '8px',
  marginBottom: '8px',
};

export const authorLabel = {
  lineHeight: '20px',
  fontSize: '14px',
  fontFamily: 'Open Sans',
  fontWeight: '400',
  color: '#383838',
  fontStyle: 'normal',
  transform: 'none',
};

export const chip = {
  backgroundColor: 'rgba(0, 0, 0, 0)',
  alignItems: 'center',
  color: 'rgb(27, 91, 160)',
  outlineColor: 'rgb(27, 91, 160)',
  textDecorationColor: 'rgb(27, 91, 160)',
  textDecorationLine: 'none',
  textDecorationStyle: 'solid',
  textDecorationThickness: 'auto',
  borderColor: 'rgba(27, 91, 160, 0.5)',
  borderRadius: '16px',
  borderStyle: 'solid',
  boxSizing: 'border-box',
  borderWidth: '0.666667px',
  borderImageOutset: 0,
  borderImageRepeat: 'stretch',
  borderImageSlice: '100%',
  borderImageSource: 'none',
  borderImageWidth: 1,
  cursor: 'default',
  display: 'inline-flex',
  fontFamily: 'Open Sans',
  fontSize: '13px',
  fontWeight: 400,
  height: '24px',
  justifyContent: 'center',
  lineHeight: '18.59px',
  outlineStyle: 'none',
  outlineWidth: '0px',
  padding: '0px',
  marginRight: '8px',
  textWrap: 'nowrap',
  verticalAlign: 'middle',
  whiteSpaceCollapse: 'collapse',
};

export const description = {
  color: '#383838',
  lineHeight: '24px',
  fontSize: '16px',
  fontFamily: 'Open Sans',
  fontWeight: 400,
  transform: 'none',
  textAlign: 'left',
};

export const selectButton = {
  width: '77px',
  height: '36px',
  borderRadius: '4px',
  padding: '8px, 12px, 8px, 12px',
  backgroundColor: '#1B5BA0',
  color: '#FFFFFF',
  lineHeight: '20px',
  fontSize: '14px',
  fontFamily: 'Open Sans',
  fontWeight: '600',
  transform: 'none',
  minWidth: '77px',
  letter: '1px',
};

export const previewButton = {
  width: '120px',
  height: '36px',
  borderRadius: '4px',
  padding: '8px, 12px, 8px, 12px',
  backgroundColor: '#FFFFFF',
  color: '#1B5BA0',
  lineHeight: '20px',
  fontSize: '14px',
  fontFamily: 'Open Sans',
  fontWeight: '600',
  transform: 'none',
  minWidth: '77px',
  letter: '1px',
  boxShadow: 'none',
  border: '1px solid #1B5BA0',
};

export const selectButtonContainer = {
  gap: '8px',
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'flex-end',
  marginRight: '15px',
  marginTop: '15px',
};

export const readMoreButtonContainer = {
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'flex-end',
  marginBottom: '10px',
  marginRight: '-10px',
};

export const readMoreButton = {
  width: '120px',
  height: '36px',
  backgroundColor: '#FFFFFF',
  color: '#1B5BA0',
  lineHeight: '20px',
  fontSize: '14px',
  fontFamily: 'Open Sans',
  fontWeight: '600',
  transform: 'none',
  minWidth: '77px',
  letter: '1px',
  boxShadow: 'none',
  '&:hover': {
    backgroundColor: '#FFFFFF',
    boxShadow: 'none',
  },
};

export const cardFooter = {
  paddingBottom: '16px',
};
export const cardComponentBox = {
  marginBottom: '2%',
};

export const card = {
  border: '1px solid #E0E0E0',
  borderRadius: '4px',
  background: '#FFFFFF',
};

export const expandMoreContainer = (expanded: boolean) => ({
  '& .MuiCardContent-root': {
    padding: 0,
  },
  display: 'flex',
  marginTop: expanded ? '20px' : '10px',
});

export const expandMoreIconContainer = {
  marginTop: '16.6px',
  marginRight: '14px',
  '& .MuiCardActions-root': {
    padding: 0,
  },
  '& .MuiButtonBase-root': {
    padding: 0,
  },
  height: '7.4px',
};
