import { raiseError } from '../../../../app/stateSlices/errorSlice';
import {
  OK_BUTTON_NAME,
  PARAMETER_ERROR_BUTTON_NAME,
  PARAMETER_ERROR_MESSAGE,
  PARAMETER_ERROR_TITLE,
} from '../../../../constants/common/error';
import {
  launchContext,
  setW3PIContextKeys,
} from '../../../../app/stateSlices/userSlice';
import storage from 'redux-persist/lib/storage/session';
import {
  ALF_COURSE_WARE,
  EREADER,
  E_BOOK,
  E_BOOKS,
  PALMER,
  READER,
} from '../../../../constants/common/search/platform';
import env from '../../../../common/env';

// Types
import { W3PIContextKeys } from '../../../type/ISearchContainer';
import { UserSessionResponse } from '../../../type/IUserSession';

// Validator
import {
  launchTypeSchema,
  w3piSchema,
} from '../../validationSchemas/LaunchQueryParams';

export const isContext = (contextType: string | null) => {
  const { error } = launchTypeSchema.validate(contextType);

  if (error) {
    storage.removeItem('launchContext');
    storage.removeItem('W3PILearningContextKey');
    storage.removeItem('W3PIMessageType');
    storage.removeItem('W3PILtiLaunchId');
    storage.removeItem('W3PIIntegrationKey');
    storage.removeItem('W3PISourceContextKey');
    storage.removeItem('IsPalmerSupported');
    return raiseError({
      error: true,
      errorTitle: PARAMETER_ERROR_TITLE,
      errorMessage: PARAMETER_ERROR_MESSAGE,
      errorButtonName:
        document.referrer === env.MY_ACCOUNT_URL
          ? PARAMETER_ERROR_BUTTON_NAME
          : OK_BUTTON_NAME,
    });
  } else {
    storage.setItem('launchContext', contextType as string);
    return launchContext({ launchContext: contextType });
  }
};

export const handleW3PIContextKeys = (W3PIContextKeys: W3PIContextKeys) => {
  const { error } = w3piSchema.validate(W3PIContextKeys);

  if (error) {
    storage.removeItem('W3PILearningContextKey');
    storage.removeItem('W3PIMessageType');
    storage.removeItem('W3PILtiLaunchId');
    storage.removeItem('W3PIIntegrationKey');
    storage.removeItem('W3PISourceContextKey');
    storage.removeItem('IsPalmerSupported');
    return raiseError({
      error: true,
      errorTitle: PARAMETER_ERROR_TITLE,
      errorMessage: PARAMETER_ERROR_MESSAGE,
      errorButtonName: OK_BUTTON_NAME,
    });
  } else {
    storage.setItem(
      'W3PILearningContextKey',
      W3PIContextKeys.learningContextKey as string
    );
    storage.setItem('W3PIMessageType', W3PIContextKeys.messageType as string);
    storage.setItem('W3PILtiLaunchId', W3PIContextKeys.ltiLaunchId as string);
    storage.setItem(
      'W3PIIntegrationKey',
      W3PIContextKeys.integrationKey as string
    );
    storage.setItem(
      'W3PISourceContextKey',
      W3PIContextKeys.sourceContextKey as string
    );
    storage.setItem(
      'IsPalmerSupported',
      W3PIContextKeys.isPalmerSupported as unknown as string
    );
    return setW3PIContextKeys({
      W3PILearningContextKey: W3PIContextKeys.learningContextKey,
      W3PIMessageType: W3PIContextKeys.messageType,
      W3PILtiLaunchId: W3PIContextKeys.ltiLaunchId,
      W3PIIntegrationKey: W3PIContextKeys.integrationKey,
      W3PISourceContextKey: W3PIContextKeys.sourceContextKey,
      IsPalmerSupported: W3PIContextKeys.isPalmerSupported,
    });
  }
};

export const setPlatform = (platform: string) => {
  switch (platform) {
    case READER: {
      return E_BOOK;
    }
    case PALMER: {
      return ALF_COURSE_WARE;
    }
    default: {
      return platform;
    }
  }
};

export const setPlatformForPairing = (platform: string) => {
  switch (platform) {
    case E_BOOK: {
      return EREADER;
    }
    case ALF_COURSE_WARE: {
      return PALMER;
    }
    default: {
      return platform;
    }
  }
};

export const setFilterPlatform = (filterBy: string) => {
  switch (filterBy) {
    case E_BOOKS: {
      return READER;
    }
    case ALF_COURSE_WARE: {
      return PALMER;
    }
    default: {
      return filterBy;
    }
  }
};

export function buildPendoData(userSession: UserSessionResponse) {
  const pendoData = {
    visitor: {
      ...(userSession.userId && { id: userSession.userId }),
      ...(userSession.courseId && { account: userSession.courseId }),
      ...(userSession.role && { role: userSession.role }),
      ...(userSession.institutionId && {
        institutionId: userSession.institutionId,
      }),
      ...(userSession.institutionName && {
        institutionName: userSession.institutionName,
      }),
      ...(userSession.lmsType && { lmsType: userSession.lmsType }),
      ...{ productId: userSession.productId },
      ...{ producttitle: userSession.productTitle },
      ...(userSession.signUpDate && { signUpDate: userSession.signUpDate }),
    },
    account: {
      ...(userSession.courseId && { id: userSession.courseId }),
      ...(userSession.institutionId && {
        institutionId: userSession.institutionId,
      }),
      ...(userSession.institutionName && {
        institutionName: userSession.institutionName,
      }),
      ...(userSession.lmsType && { lmsType: userSession.lmsType }),
      ...{ productId: userSession.productId },
      ...{ producttitle: userSession.productTitle },
      ...(userSession.ltiContextId && {
        ltiContextId: userSession.ltiContextId,
      }),
      ...{ disciplines: userSession.disciplines },
    },
  };
  return pendoData;
}
