import { createAsyncThunk } from '@reduxjs/toolkit';
import { getCookie } from '../../util/cookie';
import env from '../../common/env';
import { RequestBody } from '../types/search';
import {
  AUTH_ERROR_BUTTON_NAME,
  AUTH_ERROR_MESSAGE,
  AUTH_ERROR_TITLE,
  AUTH_ERROR_STATUS,
} from '../../constants/common/error';

export const searchOffers = createAsyncThunk(
  'offers/searchOffers',
  async (data: RequestBody, thunkAPI) => {
    const searchResponse = await execSearchRequest(data);
    const responseData = await searchResponse.json();
    if (searchResponse.status === 200) {
      return responseData;
    } else if (searchResponse.status === 401) {
      return thunkAPI.rejectWithValue({
        error: true,
        errorStatus: AUTH_ERROR_STATUS,
        errorTitle: AUTH_ERROR_TITLE,
        errorMessage: AUTH_ERROR_MESSAGE,
        errorButtonName: AUTH_ERROR_BUTTON_NAME,
      });
    } else if (searchResponse.status === 404) {
      return {
        totalCount: 0,
        searchResults: [],
      };
    }
    return thunkAPI.rejectWithValue(responseData);
  }
);

const execSearchRequest = async (
  requestBody: RequestBody
): Promise<Response> => {
  const token = getCookie('X-NG-JWT-TOKEN');
  const url = env.SEARCH_URL;

  const response = await fetch(url, {
    method: 'POST',
    mode: 'cors',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(requestBody),
  });

  return response;
};
