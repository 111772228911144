import { createAsyncThunk } from '@reduxjs/toolkit';
import { getCookie } from '../../util/cookie';
import env from '../../common/env';
import { SuggestionRequestBody } from '../types/suggestions';
import {
  AUTH_ERROR_BUTTON_NAME,
  AUTH_ERROR_MESSAGE,
  AUTH_ERROR_TITLE,
  AUTH_ERROR_STATUS,
} from '../../constants/common/error';

export const getSuggestions = createAsyncThunk(
  'suggestions/fetchSuggestions',
  async (data: SuggestionRequestBody, thunkAPI) => {
    const suggestionsResponse = await execSuggestionsRequest(data);
    const responseData = await suggestionsResponse.json();
    if (suggestionsResponse.status === 200) {
      return responseData;
    } else if (suggestionsResponse.status === 401) {
      return thunkAPI.rejectWithValue({
        error: true,
        errorStatus: AUTH_ERROR_STATUS,
        errorTitle: AUTH_ERROR_TITLE,
        errorMessage: AUTH_ERROR_MESSAGE,
        errorButtonName: AUTH_ERROR_BUTTON_NAME,
      });
    } else if (suggestionsResponse.status === 404) {
      return [];
    }
    return thunkAPI.rejectWithValue(responseData);
  }
);

const execSuggestionsRequest = async (
  requestBody: SuggestionRequestBody
): Promise<Response> => {
  const token = getCookie('X-NG-JWT-TOKEN');
  const url = env.SUGGESTIONS_URL;

  const response = await fetch(url, {
    method: 'POST',
    mode: 'cors',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(requestBody),
  });

  return response;
};
