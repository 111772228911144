import { createSlice } from '@reduxjs/toolkit';
import { SortByList } from '../../components/type/ISortByComponent';
import { sortByList } from '../../components/SortBy/SortByList';

interface SortState {
  sortOptions: Array<SortByList>;
  sortBy: string;
}

const initialState: SortState = {
  sortOptions: sortByList,
  sortBy: 'RELEASED_DATE-DESC',
};

export const sortSlice = createSlice({
  name: 'sort',
  initialState,
  reducers: {
    setSortOptions(state, action) {
      state.sortOptions = action.payload;
    },
    setSortBy(state, action) {
      state.sortBy = action.payload;
    },
    resetSortOptions(state) {
      state.sortOptions = sortByList;
    },
  },
});

export const { setSortOptions, setSortBy, resetSortOptions } =
  sortSlice.actions;
export default sortSlice.reducer;
