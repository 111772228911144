import Typography from '@mui/material/Typography';
import { Box } from '@mui/material';
import NoResultsFoundImg from '../../resources/images/NoResultsFound.svg';
import { NO_RESULTS_FOUND_LABEL } from '../../constants/common/header';
import {
  noResultsFoundContainer,
  noResultsFoundImg,
  noResultsFoundLabel,
  noResultsFoundLabelContainer,
} from './styleDefinitions/NoResultFoundStyle';

export default function NoResultFoundComponent() {
  return (
    <Box id="no_results_found">
      <Box sx={noResultsFoundContainer}>
        <img
          src={NoResultsFoundImg}
          alt="No results found image"
          style={noResultsFoundImg}
          aria-hidden="true"
          id="NoResultFound"
        />
      </Box>
      <Box sx={noResultsFoundLabelContainer}>
        <Typography sx={noResultsFoundLabel}>
          {NO_RESULTS_FOUND_LABEL}
        </Typography>
      </Box>
    </Box>
  );
}
