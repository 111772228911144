import { MY_ACCOUNT } from '../../../../constants/common/header';

export const appBar = {
  '& .MuiToolbar-root': { padding: 0 },
};

export const title = (launchContext: string) => ({
  flexGrow: 1,
  fontSize: '20px',
  fontFamily: 'Open Sans',
  fontWeight: '400',
  lineHeight: '27px',
  fontStyle: 'normal',
  display: 'flex',
  fontColor: '#FFFFFF',
  marginLeft: launchContext === MY_ACCOUNT ? 0 : '24px',
});

export const backArrow = {
  width: '16px',
  height: '16px',
  color: '#FFFFFF',
  position: 'absolute',
  left: '16%',
  right: '16%',
  top: '16%',
  bottom: '16%',
};

export const toolbar = {
  height: '64px',
};
