import { createBrowserRouter } from 'react-router-dom';
import { RootLayout } from './components/Layout/RootLayout/RootLayout';
import { SearchContainer } from './components/Search/container/SearchContainer';
import { Launch } from './components/Launch/Launch';
import { ProductPairingSuccess } from './components/ProductPairing/success/ProductPairingSuccess';
import { ProductPairingFailure } from './components/ProductPairing/failure/ProductPairingFailure';

export const router = createBrowserRouter([
  {
    path: '/',
    element: <RootLayout />,
    children: [
      {
        index: true,
        element: <SearchContainer />,
      },
      {
        path: 'launch',
        element: <Launch />,
      },
    ],
  },
  {
    path: '/pairingSuccess',
    element: <RootLayout />,
    children: [
      {
        index: true,
        element: <ProductPairingSuccess />,
      },
    ],
  },
  {
    path: '/pairingFailure',
    element: <RootLayout />,
    children: [
      {
        index: true,
        element: <ProductPairingFailure />,
      },
    ],
  },
]);
