import { useEffect, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Button,
} from '@mui/material';
import { useAppSelector, useAppDispatch } from '../../../app/hooks';
import env from '../../../common/env';
import { PARAMETER_ERROR_TITLE } from '../../../constants/common/error';
import { dialog } from './styleDefinitions/ErrorStyles';

export const ErrorDialog = () => {
  const [open, setOpen] = useState(false);
  const [errorTitle, setErrorTitle] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [buttonLabel, setButtonLabel] = useState('');

  const error = useAppSelector((state) => state.error);

  const dispatch = useAppDispatch();

  useEffect(() => {
    setOpen(error.error);
    setErrorTitle(error.errorTitle);
    setErrorMessage(error.errorMessage);
    setButtonLabel(error.errorButtonName);
  }, [error]);

  // const handleClickOpen = () => {
  //   setOpen(true);
  // };

  const handleClose = () => {
    setOpen(false);
    dispatch({ type: 'RESET_STATE' });
    if (document.referrer === env.MY_ACCOUNT_URL) {
      window.location.replace(env.LOGIN_URL);
    } else {
      close();
    }
  };

  return (
    <>
      <Dialog
        sx={dialog}
        aria-labelledby="customized-dialog-title"
        open={open}
        disableEscapeKeyDown
        data-testid={
          errorTitle === PARAMETER_ERROR_TITLE
            ? 'context-validation-error-popup'
            : 'authentication-error-popup'
        }
      >
        <DialogTitle variant="h3" color="textPrimary">
          {errorTitle}
        </DialogTitle>
        <DialogContent>
          <Typography color="textPrimary">{errorMessage}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{buttonLabel}</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
