export const sortContainer = {
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  alignItems: 'center',
  justifyContent: 'space-between',
};

export const sortComponentBox = {
  width: '100%',
};

export const inputLabel = {
  lineHeight: '16px',
  fontSize: '12px',
  fontFamily: 'Open Sans',
  fontWeight: '400',
  color: '#484848',
  fontStyle: 'normal',
  paddingLeft: '12px',
  paddingTop: '4px',
  transform: 'none',
  '&.Mui-focused': {
    color: '#484848',
  },
};

export const menuItem = {
  fontFamily: 'Open Sans',
  fontStyle: 'normal',
  fontWeight: '400',
  fontSize: '16px',
  lineHeight: '24px',
  fontColor: '#383838',
  flex: 'none',
  order: '0',
  alignSelf: 'stretch',
  flexGrow: '0',
  display: 'flex',
  padding: '6px 16x',
  '&.Mui-focusVisible': {
    '&:after': { border: 0 },
  },
};

export const select = (menuVisible: boolean) => ({
  height: '48px',
  '& .MuiSelect-icon': {
    color: '#484848',
  },
  '& .MuiSvgIcon-root': {
    right: '21px',
  },
  background: 'rgba(0, 0, 0, 0.05)',
  lineHeight: '24px',
  fontSize: '16px',
  fontFamily: 'Open Sans',
  fontWeight: '400',
  color: '#383838',
  fontStyle: 'normal',
  '&:after': {
    border: menuVisible ? '1px solid #1B5BA0' : 'none',
  },
});

export const filterComponent = {
  height: '48px',
  background: 'rgba(0, 0, 0, 0.05)',
  '& .MuiSelect-select': {
    paddingLeft: '12px',
    paddingRight: '41px',
    paddingTop: '21px',
    paddingBottom: '4px',
  },
  '& .MuiInputBase-root.Mui-disabled': {
    background: 'rgba(0, 0, 0, 0.05)',
  },
};

export const paperProps = {
  maxHeight: '50%',
  lineHeight: '24px',
  fontSize: '16px',
  fontFamily: 'Open Sans',
  fontWeight: '400',
  color: '#383838',
  fontStyle: 'normal',
  '& .MuiMenuItem-root': {
    height: '36px',
  },
};
