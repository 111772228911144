import { Outlet } from 'react-router-dom';

import { FlintThemeProvider } from '@wiley-flint/theme';

// Mui Components
import Grid from '@mui/material/Unstable_Grid2';
import CircularProgress from '@mui/material/CircularProgress';

// Components
import { Header } from '../../common/Header/Header';
import { ErrorDialog } from '../../common/Error/Dialog';

import { store, persistor } from '../../../app/store';
import { Provider } from 'react-redux';

import { PersistGate } from 'redux-persist/integration/react';

export const RootLayout = () => {
  return (
    <Provider store={store}>
      <PersistGate loading={<CircularProgress />} persistor={persistor}>
        <FlintThemeProvider variant="new" type="light">
          <ErrorDialog />
          <Grid container>
            <Grid xs={12}>
              <Header />
            </Grid>
            <Grid xs={12}>
              <Outlet />
            </Grid>
          </Grid>
        </FlintThemeProvider>
      </PersistGate>
    </Provider>
  );
};
