import { createSlice } from '@reduxjs/toolkit';

interface FilterState {
  filterBy: string;
}

const initialState: FilterState = {
  filterBy: 'All Products',
};

export const filterSlice = createSlice({
  name: 'filter',
  initialState,
  reducers: {
    setFilterBy(state, action) {
      state.filterBy = action.payload;
    },
  },
});

export const { setFilterBy } = filterSlice.actions;
export default filterSlice.reducer;
