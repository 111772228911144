export interface RequestBody {
  sourceContextKey: string;
  productId: string;
  ltiLaunchId: string;
  integrationKey: string;
  learningContextKey: string;
  targetPlatform: string;
}

export const initProductPairReqBody = (
  options?: Partial<RequestBody>
): RequestBody => {
  const defaults = {
    sourceContextKey: '',
    productId: '',
    ltiLaunchId: '',
    integrationKey: '',
    learningContextKey: '',
    targetPlatform: 'EREADER',
  };

  return {
    ...defaults,
    ...options,
  };
};
