import { Autocomplete, Box, TextField } from '@mui/material';
import { useState, useEffect, useCallback } from 'react';
import CancelIcon from '@mui/icons-material/Cancel';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import {
  listBoxProps,
  textFieldInputProps,
  listBoxItem,
  textField,
  listItem,
  clearIcon,
  autoComplete,
  suggestionItem,
} from './styleDefinitions/SearchComponentStyles';
import { SearchProps } from '../../type/ISearchComponent';

// Redux
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { setSelectedInputValue } from '../../../app/stateSlices/offerSlice';
import { setFilterBy } from '../../../app/stateSlices/filterSlice';
import { filterByList } from '../../filter/filterByList';

export const SearchComponent = (props: SearchProps) => {
  const [menuVisible, setMenuVisible] = useState(false);
  const [clearIconDisabled, setClearIconDisabled] = useState(true);
  const [searchValue, setSearchValue] = useState('');
  const [clearIconNode, setClearIconNode] = useState<HTMLElement | null>(null);

  const selectedInputValue = useAppSelector(
    (state) => state.offer.selectedInputValue
  );

  const suggestionsList = useAppSelector(
    (state) => state.suggestions.suggestions
  );

  const searchQuery = useAppSelector((state) => state.offer.searchQuery);
  const searchFilter = useAppSelector((state) => state.filter.filterBy);

  const isPalmerSupported = useAppSelector(
    (state) => state.user.IsPalmerSupported
  );
  const dispatch = useAppDispatch();

  const hideMenu = () => {
    setMenuVisible(false);
  };

  const handleClearClick = () => {
    if (searchQuery !== '') {
      setSearchValue('');
      props?.handleSearch('', filterByList[isPalmerSupported ? 0 : 2]);
      dispatch(setFilterBy(filterByList[isPalmerSupported ? 0 : 2]));
    }
  };

  const handleInputClear = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (!value && value !== searchQuery) {
      setSearchValue('');
      dispatch(setFilterBy(filterByList[isPalmerSupported ? 0 : 2]));
      props?.handleSearch('', filterByList[isPalmerSupported ? 0 : 2]);
    }
  };

  const clearIconCallback = useCallback(
    (node: any) => {
      if (searchQuery && node) {
        setClearIconNode(node);
      }
    },
    [searchQuery]
  );

  useEffect(() => {
    window.addEventListener('resize', hideMenu);
    return () => window.removeEventListener('resize', hideMenu);
  }, []);

  //Handle clear icon visibility
  useEffect(() => {
    if (selectedInputValue) {
      setClearIconDisabled(false);
    } else if (!clearIconDisabled) {
      setClearIconDisabled(true);
    }
  }, [selectedInputValue]);

  useEffect(() => {
    if (clearIconNode) {
      clearIconNode.addEventListener('click', handleClearClick);

      return () => {
        clearIconNode.removeEventListener('click', handleClearClick);
      };
    }
  }, [clearIconNode]);

  const getSuggestionsOptions: any = (
    selectedInputValue: string,
    suggestionsList: any
  ) => {
    if (
      selectedInputValue.length > 2 &&
      suggestionsList &&
      suggestionsList.length > 0
    ) {
      return suggestionsList;
    } else {
      return [];
    }
  };

  return (
    <Autocomplete
      freeSolo
      clearIcon={
        <CancelIcon
          id="cancel-icon_test"
          sx={clearIcon}
          ref={clearIconCallback}
        />
      }
      disableClearable={clearIconDisabled}
      id="highlights-demo"
      data-testid={'search_component_test'}
      blurOnSelect
      options={
        getSuggestionsOptions(selectedInputValue, suggestionsList) as string[]
      }
      getOptionLabel={(option) => option}
      open={menuVisible}
      onOpen={() => {
        // setClearIconDisabled(true);
        setMenuVisible(true);
      }}
      sx={autoComplete}
      onClose={() => {
        setMenuVisible(false);
        // setClearIconDisabled(false);
      }}
      inputValue={selectedInputValue}
      ListboxProps={{
        style: listBoxProps,
      }}
      onChange={(event, value, reason) => {
        if ((reason === 'createOption' || reason === 'selectOption') && value) {
          setSearchValue(value as string);
          props?.handleSearch(value as string, searchFilter as string);
        }
      }}
      onInputChange={(e, value) => {
        dispatch(setSelectedInputValue(value));
        // if (!value || value.length < 3) {
        //   setMenuVisible(false);
        // }
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Search by keywords, title, author or ISBN"
          variant="filled"
          sx={textField(props.noResultFound, selectedInputValue)}
          InputProps={{
            ...params.InputProps,
            style: textFieldInputProps,
            onKeyDown: (e) => {
              if (
                e.key === 'Enter' &&
                searchValue === selectedInputValue &&
                selectedInputValue !== searchQuery
              ) {
                props?.handleSearch(
                  selectedInputValue as string,
                  searchFilter as string
                );
                setMenuVisible(false);
              }
            },
            onChange: handleInputClear,
          }}
        />
      )}
      renderOption={(props, option, { inputValue }) => {
        const matches = match(option, inputValue, { insideWords: true });
        const parts = parse(option, matches);
        return (
          <li {...props} style={listItem}>
            <Box sx={suggestionItem}>
              {parts.map(
                (
                  part: { highlight: boolean; text: string },
                  index: React.Key
                ) => (
                  <span key={index} style={listBoxItem(part)}>
                    {part.text}
                  </span>
                )
              )}
            </Box>
          </li>
        );
      }}
    />
  );
};
