import { createAsyncThunk } from '@reduxjs/toolkit';
import { getCookie } from '../../util/cookie';
import env from '../../common/env';
import {
  AUTH_ERROR_BUTTON_NAME,
  AUTH_ERROR_MESSAGE,
  AUTH_ERROR_TITLE,
  AUTH_ERROR_STATUS,
} from '../../constants/common/error';

export const getDisciplines = createAsyncThunk(
  'disciplines/fetchDisciplines',
  async (isPalmerSupported: boolean, thunkAPI) => {
    const token = getCookie('X-NG-JWT-TOKEN');
    const disciplinesResponse = await fetchDisciplines(
      token,
      isPalmerSupported
    );
    const responseData = await disciplinesResponse.json();
    if (disciplinesResponse.status === 200) {
      return responseData;
    } else if (disciplinesResponse.status === 401) {
      return thunkAPI.rejectWithValue({
        error: true,
        errorStatus: AUTH_ERROR_STATUS,
        errorTitle: AUTH_ERROR_TITLE,
        errorMessage: AUTH_ERROR_MESSAGE,
        errorButtonName: AUTH_ERROR_BUTTON_NAME,
      });
    }
    return thunkAPI.rejectWithValue(responseData);
  }
);

const fetchDisciplines = async (token: string, isPalmerSupported: boolean) => {
  const url = `${env.DISCIPLINES_URL}?isPalmerSupported=${isPalmerSupported}`;

  const response = await fetch(url, {
    method: 'GET',
    mode: 'cors',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response;
};
