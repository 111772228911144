import { createSlice } from '@reduxjs/toolkit';
import { searchOffers } from '../thunks/searchThunk';
import { SearchResult } from '../../components/type/ISearchComponent';

interface offerState {
  offers: Array<SearchResult>;
  totalSearchResults: number;
  loading: boolean;
  noResultFound: boolean;
  searchResultsArr: Array<SearchResult>;
  isSearchResultArr: boolean;
  selectedInputValue: string;
  searchResultLabel: string;
  searchQuery: string;
  searchFrom: number;
  isReadMoreOpen: Array<string>;
}

const initialState: offerState = {
  offers: [],
  totalSearchResults: 0,
  loading: false,
  noResultFound: true,
  searchResultsArr: [],
  isSearchResultArr: false,
  selectedInputValue: '',
  searchResultLabel: '',
  searchQuery: '',
  searchFrom: 0,
  isReadMoreOpen: [],
};

export const offerSlice = createSlice({
  name: 'offers',
  initialState,
  reducers: {
    setNoResultFound(state, action) {
      state.noResultFound = action.payload;
    },
    setSearchResultsArr(state, action) {
      state.searchResultsArr = action.payload;
    },
    setIsSearchResultArr(state, action) {
      state.isSearchResultArr = action.payload;
    },
    setSelectedInputValue(state, action) {
      state.selectedInputValue = action.payload;
    },
    setSearchResultLabel(state, action) {
      state.searchResultLabel = action.payload;
    },
    setSearchQuery(state, action) {
      state.searchQuery = action.payload;
    },
    setSearchFrom(state, action) {
      state.searchFrom = action.payload;
    },
    setIsReadMoreOpen(state, action) {
      state.isReadMoreOpen[action.payload] = 'open';
    },
    setIsReadMoreOpenArray(state, action) {
      state.isReadMoreOpen = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(searchOffers.pending, (state) => {
        state.loading = true;
      })
      .addCase(searchOffers.fulfilled, (state, action) => {
        state.loading = false;
        state.offers = action.payload.searchResults;
        state.totalSearchResults = action.payload.totalCount;
      })
      .addCase(searchOffers.rejected, (state) => {
        state.loading = false;
      });
  },
});

export const {
  setNoResultFound,
  setSearchResultsArr,
  setIsSearchResultArr,
  setSelectedInputValue,
  setSearchResultLabel,
  setSearchQuery,
  setSearchFrom,
  setIsReadMoreOpen,
  setIsReadMoreOpenArray,
} = offerSlice.actions;
export default offerSlice.reducer;
