export const loadMoreButton = {
  width: '140px',
  height: '40px',
  borderRadius: '4px',
  backgroundColor: '#FFFFFF',
  color: '#383838',
  fontSize: '15px',
  fontFamily: 'Open Sans',
  fontWeight: '600',
  transform: 'none',
  minWidth: '100px',
  letter: '1px',
  boxShadow: 'none',
  border: '1px solid #C4C4C4',
};

export const loadMoreComponent = {
  paddingBottom: '22px',
  paddingTop: '8px',
};
