import { createSlice } from '@reduxjs/toolkit';
import { getUser } from '../thunks/userThunk';

interface UserState {
  launchContext: string;
  W3PILearningContextKey: string;
  W3PIMessageType: string;
  W3PILtiLaunchId: string;
  W3PIIntegrationKey: string;
  W3PISourceContextKey: string;
  IsPalmerSupported: boolean;
  userValidated: boolean;
  user: User;
}

interface User {
  edPubUserKey: string;
  email: string;
  firstName: string;
  lastName: string;
  lmsRole: string;
  ngUserType: string;
  userKey: string;
}

const initialState: UserState = {
  launchContext: '',
  W3PILearningContextKey: '',
  W3PIMessageType: '',
  W3PILtiLaunchId: '',
  W3PIIntegrationKey: '',
  W3PISourceContextKey: '',
  IsPalmerSupported: false,
  userValidated: false,
  user: {} as User,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    launchContext(state, action) {
      state.launchContext = action.payload.launchContext;
    },
    setW3PIContextKeys(state, action) {
      state.W3PILearningContextKey = action.payload.W3PILearningContextKey;
      state.W3PIMessageType = action.payload.W3PIMessageType;
      state.W3PILtiLaunchId = action.payload.W3PILtiLaunchId;
      state.W3PIIntegrationKey = action.payload.W3PIIntegrationKey;
      state.W3PISourceContextKey = action.payload.W3PISourceContextKey;
      state.IsPalmerSupported = action.payload.IsPalmerSupported;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getUser.fulfilled, (state, action) => {
      state.userValidated = true;
      state.user = action.payload;
    });
  },
});

export const { launchContext, setW3PIContextKeys } = userSlice.actions;
export default userSlice.reducer;
